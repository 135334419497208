<template>
    <div class='alipay'>
        <div class="alipay_head">
            <img src="../assets/imgs/fc_icon.png"/>
            <p>上海福利彩票发行中心</p>
        </div>
        <div class="alipay_input">
            <p>选择或输入充值金额(元)</p>
            <div class="alipay_input_box">
                <label>￥</label><input ref="inputRef" @input="inputEvent" v-model="inputValue" placeholder="请输入您的充值金额"/>
            </div>
        </div>
        <div class="alipay_choose">
                <div 
                    class="alipay_choose_item" 
                    :class="{'alipay_choose_active': index == currentMoneyItemsIndex}"
                    v-for="(item, index) in moneyItems" 
                    @click="changeMoney(index, item.value)"
                    :key="index">
                        <button v-if="item.type == 'button'">
                            <span>{{item.value}}元</span>
                            <!-- <span :class="{'red': item.discounts && index !== currentMoneyItemsIndex}">{{item.discounts ? item.info : '暂无优惠'}}</span> -->
                        </button>
                </div>
            </div>
        <div class="alipay_tips">
            <h3>温馨提示：</h3>
            <ul>
                <li v-for="(item, index) in tips" :key="index">{{index+1}}. {{item}}</li>
            </ul>
        </div>
        <div class="alipay_submit">
            <button @click="submit">立即充值</button>
        </div>
    </div>
</template>
<script>
import { reactive, toRefs, ref, onMounted } from 'vue'
import { aliPay } from '../utils/api'
import { getQueryStr, inIos, sleep, toast} from '../utils/util'
export default {
    name: 'alipay',
    setup() {
         const state= reactive({
             moneyItems: [
                 {type: 'button', value: 10, discounts: false},
                 {type: 'button', value: 30, discounts: false},
                 {type: 'button', value: 50, discounts: true, info: '送2注共4元'},
                 {type: 'button', value: 100, discounts: true, info: '送6注共12元'},
                 {type: 'button', value: 200, discounts: true, info: '送15注共30元'},
                 {type: 'button', value: 500, discounts: true, info: '送15注共30元'}, 
                 
             ],
             currentMoneyItemsIndex: 3,
             inputValue: '100',
             tips: [
                 '充值金额最少为10元；',
                 '充值所有钱款均到上海福彩账户中心；',
                 '充值彩票款仅限短信投注，不可提现。',
                //   '用户一次性充值：满50元送2注共4元，充值满100元送6注共12元、充值满200元送15注共30元,先到先得，送完为止（获赠的机选单式“双色球”彩票于次日一次性发送至该用户的电话投注账户，每个账户每月仅限参与一次充值活动）'
             ]
        })
        const inputRef = ref()
        console.log(inputRef)
        const cardNo = getQueryStr('payBetCardNo')
        const storeId = getQueryStr('storeId')
        async function submit() {
            try {
                const value = Number.parseFloat(state.inputValue)
                if(!value || isNaN(value) || value < 10) {
                    toast('最小充值金额为10元')
                    return
                }
                const params = {
                    money: state.inputValue,
                    payBetCardNo: cardNo,
                    storeId: storeId
                }
                console.log(params)
                const result = await aliPay(params)
                if (result.code == 0) {
                    if (inIos) {
                        window.location.href = result.data
                    } else {
                        window.open(result.data)
                    }
                }
            } catch(e) {
                console.error(e)
            }
        }
        function changeMoney(index, value) {
            state.currentMoneyItemsIndex = index
            state.inputValue = value
            inputRef.value.focus()
        }
        onMounted(() => {
            inputRef.value.focus()
        })
        async function inputEvent(e) {
            state.currentMoneyItemsIndex = 9
            e.target.value = e.target.value.replace(/[^\d]/g,'')
            const inputValue = e.data
            const data = e.target.value
            if (inputValue == '.') {
                state.inputValue = parseInt(data)
                state.inputValue = state.inputValue + 1
                await sleep(2)
                state.inputValue = state.inputValue - 1
            }
        }
        return {
            ...toRefs(state),
            submit,
            changeMoney,
            inputRef,
            inputEvent
        }
    },
}
</script>
<style lang='scss' scoped>
.alipay {
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   overflow: auto;
   background: #fff;
   &_head {
       padding: 40px 0 10px 0;
       text-align: center;
       font-size: $font-big;
       font-weight: 700;
       img {
           display: inline-block;
           width: 80px;
           margin-bottom: 10px;
       }
   }
   &_input {
       padding: 20px 10px 0 10px;
       &>p {
           font-size: 17px;
           small {
               color: $gray-middle;
               padding-left: 5px;
               font-size: $font-middle;
           }
       }
       &_box {
           display: flex;
           font-size: 42px;
           margin: 10px 0 0 0;
           input {
               font-size: 36px;
               font-weight: 600;
               max-width: 80%;
               &::placeholder{
                   font-size: $font-big;
                   font-weight: 400;
                   line-height: 36px;
               }
           }
       }
   }
   &_choose {
       margin: 0px 10px 0px 10px;
       padding-bottom: 10px;
       display: flex;
       flex-wrap: wrap;
       justify-content: space-between;
       align-items: center;
       border-bottom: solid $line-gray 1px;
       &_item {
            width: 30%;
            background: #fff;
            color: $theme-dark;
            background: rgba($theme-dark, 0.08);
            flex-wrap: nowrap;
            // border: solid $gray-light 1px;
            border-radius: $box-radius-small;
            box-sizing: border-box;
            overflow: hidden;
            margin: 13px 0 0 0;
            padding: 10px 0 10px 0;
            text-align: center;
            font-size: $font-max;
            border: solid rgba($theme-dark, 0.08) 1px;
            // span {
            //     display: block;
            //     // &:last-child{
            //     //     font-size: $font-small;
            //     // }
            // }
       }
       &_active {
            border: solid $theme-dark 1px;
            background: rgba($theme-dark, 0.08) url('../assets/imgs/tangle-icon.png') right bottom no-repeat;
            background-size: 16px;
            color: $theme-dark;
       }
   }
    &_tips{
        padding: $margin-big;
        h3 {
            font-weight: 400;
            color: $theme-dark;
            font-size: $font-small;
            padding: 15px 0 0px 0;
        }
        li {
            font-size: $font-small;
            color: $gray-middle;
            padding: 5px 0 0 0;
        }
    }
    &_submit {
        margin: $margin-big;
        button {
            color: #fff;
            width: 100%;
            text-align: center;
            background: $theme-dark;
            border-radius: $box-radius-small;
            padding: 15px 10px;
        }
    }
}
</style>